import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import AboutOne from "@/components/about-one";
import CallToActionOne from "@/components/call-to-action-one";
import TeamCarousel from "@/components/team-carousel";
import TestimonialsOneCarousel from "@/components/testimonials-carousel";
import FeatureTabOne from "@/components/feature-tab-1";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SEO from "@/components/seo";

const AboutPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Solxk - Über uns"
          description="Erfahren Sie mehr über Solxk.Wir sind ein engagiertes Team von Entwicklern und Cybersicherheitsexperten, die sich zur Bereitstellung innovativer Lösungen für Ihre Geschäftsanforderungen einsetzen."
        />
        <Layout PageTitle="About Us">
          <HeaderOne />
          <PageBanner title="Über uns" name="About" />
          <AboutOne />
          <TestimonialsOneCarousel />
          <TeamCarousel />
          <FeatureTabOne />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AboutPage;
